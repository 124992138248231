import React from "react"
import styled from "styled-components"

const TextAreaLayout = ({ className, label, name, required, onChange }) => (
  <div className={className}>
    <label htmlFor={name}>
      {label} {!required && "(optional)"}
    </label>
    <textarea name={name} onChange={onChange} id={name} required={required} rows="5" />
  </div>
)

const TextArea = styled(TextAreaLayout)`
  width: 100%;
  margin: 1rem auto;
`

export default TextArea
