import React from "react"
import styled from "styled-components"

import IconGroup from "../molecules/IconGroup"

const SectionLayout = ({ className, iconGroup }) => (
  <section className={className}>
    <div className="icon-section__inner">
      {iconGroup.map((group) => (
        <IconGroup key={group.heading} {...group}>
          <div dangerouslySetInnerHTML={{ __html: group.textNode.childMarkdownRemark.html }} />
        </IconGroup>
      ))}
    </div>
  </section>
)

const IconSection = styled(SectionLayout)`
  width: 100%;
  display: flex;
  justify-content: center;

  .icon-section__inner {
    width: 100%;
    max-width: ${({ theme }) => theme.maxWidth}px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 1.5rem 0;
  }

  @media (min-width: ${({ theme }) => theme.mobileBreakpoint}px) {
    .icon-section__inner {
      flex-direction: row;
    }
  }
`

export default IconSection
