import styled from "styled-components"
import Img from "gatsby-image"

const Image = styled(Img)`
  width: 100%;
  height: auto;
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
`

export default Image
