import React from "react"
import styled from "styled-components"

import Image from "../atoms/Image"
import { GhostButton } from "../atoms/Button"

const PhotoTextLayout = ({ className, image, heading, content, textNode, linkURL, linkText, children }) => (
  <section className={className}>
    <div className="photo-text__inner">
      <div className="photo-text__image-wrap">
        <Image fluid={image.fluid} />
      </div>
      <div className="photo-text__content-container">
        {heading && <h2>{heading}</h2>}
        {content ||
          (textNode.childMarkdownRemark.html && (
            <div
              className="photo-text__content"
              dangerouslySetInnerHTML={{ __html: content || textNode.childMarkdownRemark.html }}
            />
          ))}
        {linkURL && <GhostButton link={linkURL}>{linkText}</GhostButton>}
        {children}
      </div>
    </div>
  </section>
)

const PhotoTextSection = styled(PhotoTextLayout)`
  width: 100%;
  display: flex;
  justify-content: center;
  .photo-text__inner {
    width: 100%;
    max-width: ${({ theme }) => theme.maxWidth}px;
    display: flex;
    flex-flow: column;
    padding: 0;
  }
  .photo-text__content-container {
    padding: 2rem;
    overflow: hidden;

    h2 {
      display: inline-block;
      position: relative;
      margin-bottom: 2rem;
      &:after {
        content: "";
        width: 100%;
        height: 2px;
        background: ${({ theme }) => theme.primary};
        position: absolute;
        bottom: -1rem;
        left: 0;
      }
    }
  }
  .photo-text__content {
    margin-bottom: 1rem;
  }

  @media (min-width: ${({ theme }) => theme.mobileBreakpoint}px) {
    .photo-text__inner {
      flex-flow: ${(props) => (props.imageOn === "right" ? "row-reverse nowrap" : "row nowrap")};
      align-items: center;
    }
    .photo-text__image-wrap {
      flex: 0 0 50%;
      margin: 2rem 0;
    }
    .photo-text__content-container {
      flex: 0 1 50%;
      padding: 2rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.tabletBreakpoint}px) {
    .photo-text__content-container {
      padding: 2rem 3rem;
    }
  }
`

export default PhotoTextSection
