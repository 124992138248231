import React from "react"

import IconSection from "./IconSection"
import LocationSection from "./LocationSection"
import PhotoTextSection from "./PhotoTextSection"
import TextSection from "./FreeformTextSection"
import TestimonialsSection from "./TestimonialsSection"
import ImageGridSection from "./ImageGridSection"
import ContactFormSection from "../molecules/ContactForm"

const dynamicSections = {
  IconSection,
  LocationSection,
  PhotoTextSection,
  TextSection,
  TestimonialsSection,
  ImageGridSection,
  ContactFormSection,
}

const DynamicSections = ({ sections, location }) => {
  const { pathname } = location

  return (
    sections &&
    sections.map((section, idx) => {
      const isDarkSection = idx % 2 ? "dark" : false
      const type = section.internal.type.replace("DatoCms", "")
      const Tag = dynamicSections[type]
      return <Tag className={isDarkSection} key={section.id} {...section} pathname={pathname} />
    })
  )
}

export default DynamicSections
