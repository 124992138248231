import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Image from "../atoms/Image"
import Lightbox from "../atoms/Lightbox"

const ImageGridLayout = ({ className, imageGallery: { imageGallery, galleryTitle } }) => {
  const isAbsolute = /(?:^[a-z][a-z0-9+.-]*:|\/\/)/gim
  const initial = { show: false, selected: { image: null, caption: null } }
  const [lightbox, setLightbox] = useState(initial)
  const closeLightbox = () => setTimeout(() => setLightbox(initial), 0.3)

  const meanAspectRatio = imageGallery.reduce((acc, curr, idx, arr) => {
    const aspectRatio = curr.imageFile.fluid.aspectRatio
    const isLastIndex = idx === arr.length - 1

    if (isLastIndex) {
      return (acc + aspectRatio) / idx
    }

    return acc + aspectRatio
  }, 0)

  return (
    <section className={className}>
      <div className="image-grid__inner">
        {galleryTitle && <h2>{galleryTitle}</h2>}
        <div className="image-grid__wrap">
          {imageGallery.map(({ imageFile, alt, title, caption, link }, idx) => (
            <div key={idx} className="image-grid__item">
              {link ? (
                isAbsolute.test(link) ? (
                  <a href={link}>
                    <Image sizes={{ ...imageFile.fluid, aspectRatio: meanAspectRatio }} alt={alt} />
                  </a>
                ) : (
                  <Link to={link}>
                    <Image sizes={{ ...imageFile.fluid, aspectRatio: meanAspectRatio }} alt={alt} />
                  </Link>
                )
              ) : (
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => setLightbox({ show: true, selected: { image: imageFile.fluid, caption } })}
                  onKeyUp={(e) => (e.key === "enter" ? () => setLightbox({ show: true, selected: imageFile }) : false)}
                >
                  <Image sizes={{ ...imageFile.fluid, aspectRatio: meanAspectRatio }} alt={alt} />
                </div>
              )}

              <div className="image-grid__caption">
                <h3>{title}</h3>
                <p>{caption}</p>
              </div>
            </div>
          ))}
          {lightbox.show && (
            <Lightbox
              animate={lightbox.show}
              image={lightbox.selected.image}
              caption={lightbox.selected.caption}
              close={closeLightbox}
            />
          )}
        </div>
      </div>
    </section>
  )
}

const ImageGrid = styled(ImageGridLayout)`
  width: 100%;
  display: flex;
  justify-content: center;

  h2 {
    width: 100%;
    text-align: ${(props) => props.imageGallery.titleAlign};
  }

  .image-grid__inner {
    width: 100%;
    max-width: ${({ theme }) => theme.maxWidth}px;
    display: flex;
    flex-flow: row wrap;
    padding: 0;
    margin: 2rem 0;
  }
  .image-grid__wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
  }
  .image-grid__item {
    flex: 0 0 ${(props) => 100 / props.imageGallery.rowMin}%;
    padding: 1rem;
    ${Image} {
      cursor: zoom-in;
      transition: all 0.2s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .image-grid__caption {
    h3 {
      margin: 1rem 0 0.5rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.mobileBreakpoint}px) {
    .image-grid__item {
      max-width: ${(props) => props.theme.maxWidth / props.imageGallery.rowMax}px;
    }
  }
`
export default ImageGrid
