import React from "react"
import styled from "styled-components"

import Icon from "../atoms/Icon"

const IconGroupLayout = ({ className, icon, heading, linkUrl, linkText, children }) => (
  <div className={className}>
    <Icon symbol={icon} size="40px" />
    <h2>{heading}</h2>
    {children && children}
    {linkUrl && <a href={linkUrl}>{linkText}</a>}
  </div>
)

const IconGroup = styled(IconGroupLayout)`
  display: flex;
  flex-flow: column;
  padding: 2rem;
  align-items: center;
  flex: 1;
  text-align: center;
  h2 {
    margin: 1rem auto;
    font-size: 1.5rem;
  }
  a {
    text-decoration-color: ${({ theme }) => theme.primary};
  }
`

export default IconGroup
