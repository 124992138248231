import React from "react"
import styled from "styled-components"

import { GhostButton } from "../atoms/Button"

const LocationLayout = ({ className, mapEmbed, heading, text, linkURL, linkText }) => (
  <section className={className}>
    <div className="location__map" dangerouslySetInnerHTML={{ __html: mapEmbed }}></div>
    <div className="location__content-container">
      <h2>{heading}</h2>
      <p>{text}</p>
      {linkURL && <GhostButton link={linkURL}>{linkText}</GhostButton>}
    </div>
  </section>
)

const LocationSection = styled(LocationLayout)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .location__map {
    width: 100%;
    iframe {
      width: 100%;
      height: 100% !important;
      min-height: 250px;
      margin: 0;
      padding: 0;
      border: 0;
    }
  }
  .location__content-container {
    padding: 2rem;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    h2 {
      display: inline-block;
      position: relative;
      letter-spacing: 1px;
      margin-bottom: 2rem;
      &:after {
        content: "";
        width: 100%;
        height: 2px;
        background: ${({ theme }) => theme.primary};
        position: absolute;
        bottom: -1rem;
        left: 0;
      }
    }
  }
  @media (min-width: ${({ theme }) => theme.mobileBreakpoint}px) {
    flex-wrap: nowrap;
    .location__map {
      width: 50%;
      iframe {
        min-height: 350px;
      }
    }
    .location__content-container {
      width: 50%;
    }
  }
  @media (min-width: ${({ theme }) => theme.tabletBreakpoint}px) {
    .location__map {
      flex: 0 1 55%;
    }
    .location__content-container {
      width: 100%;
      max-width: calc(${({ theme }) => theme.maxWidth}px * 0.45);
      padding: 3rem;
    }
  }
`

export default LocationSection
