import React, { useEffect, useState } from "react"
import styled from "styled-components"

const DateTimeLayout = ({ className, label }) => {
  const [minDate, setMinDate] = useState(new Date().toISOString().split("T")[0])

  useEffect(() => {
    const clientDate = new Date().toISOString().split("T")[0]
    if (minDate !== clientDate) setMinDate(clientDate)
  }, [minDate])

  return (
    <div className={className}>
      <strong>{label}</strong>
      <div className="datetime__row">
        <div className="datetime__col">
          <label htmlFor="date">Date</label>
          <input type="date" name="date" id="date" min={minDate} required />
        </div>
        <div className="datetime__col">
          <label htmlFor="time">Time</label>
          <input type="time" name="time" id="time" required />
        </div>
      </div>
    </div>
  )
}

const DateTime = styled(DateTimeLayout)`
  width: 100%;
  margin: 1rem auto;
  .datetime__row {
    display: flex;
    flex-flow: column;
  }
  .datetime__col {
    margin: 1rem 0;
    display: flex;
    flex-flow: column nowrap;
  }
  @media (min-width: ${({ theme }) => theme.mobileBreakpoint}px) {
    .datetime__row {
      flex-flow: row;
    }
    .datetime__col {
      flex: 1 1 50%;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
`

export default DateTime
