import React from "react"
import styled from "styled-components"

const InputLayout = ({ className, name, type, label, required, autocomplete, validate, placeholder, onChange }) => (
  <div className={className}>
    <label htmlFor={name}>
      {label} {!required && "(optional)"}
    </label>
    <input
      name={name}
      id={name}
      type={type ? type : "text"}
      required={required}
      autoComplete={autocomplete}
      className={validate && "validate"}
      placeholder={placeholder}
      onChange={onChange}
    />
  </div>
)

const Input = styled(InputLayout)`
  width: 100%;
  margin: 1rem auto;
`

export default Input
