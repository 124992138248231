import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import Img from "gatsby-image"
import { transparentize } from "polished"

const BlockScrolling = createGlobalStyle`
  body {
    height: 100%;
    overflow: hidden;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => transparentize(0.5, theme.dark())};
  z-index: 9999;
  cursor: zoom-out;
  transition: 0.3s;
`

const Figure = styled.figure`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 80%;
  padding: 0.5rem;
  background: #fff;

  @media (min-width: ${({ theme }) => theme.mobileBreakpoint}px) {
    min-width: 50%;
  }
`

const Image = styled(Img)`
  width: 100%;
  cursor: default;
  max-height: 90vh;
`

const Caption = styled.figcaption`
  padding: 0.5rem 0 0;
`

const Lightbox = ({ image, caption, close }) => (
  <>
    <BlockScrolling />
    <Overlay onClick={close}>
      <Figure onClick={(e) => e.stopPropagation()}>
        <Image fluid={image} />
        {caption && <Caption>{caption}</Caption>}
      </Figure>
    </Overlay>
  </>
)

export default Lightbox
