import React, { useState } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"

import Input from "../atoms/Input"
import DateTime from "../atoms/DateTime"
import TextArea from "../atoms/TextArea"
import maybeTrack from "../../utilities/analytics"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const FormLayout = ({ className, title, phone, dateTime, pathname }) => {
  const [state, setState] = useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => {
        const { fbq, ga } = maybeTrack(e)

        if (fbq) fbq("track", "Contact")
        if (ga) ga("send", "event", "Contact", form.getAttribute("name"))

        navigate(form.getAttribute("action"))
      })
      .catch((error) => alert(error))
  }

  const formName = title.replace(/\s/g, "-").toLowerCase()

  return (
    <section className={className}>
      <div className="contact-form__inner">
        {title && <h2>{title}</h2>}
        <form
          name={formName}
          onSubmit={handleSubmit}
          action="/thank-you/"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <Input label="Name" name="name" required autocomplete="name" onChange={handleChange} />
          <Input
            label="Email"
            name="email"
            type="email"
            required
            autocomplete="email"
            validate={true}
            onChange={handleChange}
            placeholder=" "
          />
          {phone && <Input label="Phone" type="tel" autocomplete="tel" />}
          {dateTime && <DateTime label="Desired appointment" />}
          <TextArea label="Message" name="message" onChange={handleChange} />
          <div className="hidden">
            <label htmlFor="bot-field">
              Leave this blank if you're a human. Robots should enter their serial number.
            </label>
            <input type="text" name="bot-field" id="bot-field" autoComplete="off" onChange={handleChange} />
          </div>
          <input type="hidden" name="form-name" value={formName} />
          <input type="submit" value="Send" />
        </form>
      </div>
    </section>
  )
}

const ContactForm = styled(FormLayout)`
  width: 100%;
  display: flex;
  justify-content: center;
  .hidden {
    display: none;
  }
  .contact-form__inner {
    flex-flow: column;
    width: 100%;
    max-width: ${({ theme }) => theme.maxWidth}px;
    padding: 2rem 1rem;
  }
  form {
    display: flex;
    flex-flow: column;
    width: 100%;
  }
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
  input,
  select,
  textarea {
    width: 100%;
    max-width: 100%;
    padding: 0.5rem;
    outline: none;
    transition: box-shadow 0.3s ease;
    border: 1px solid ${({ theme }) => theme.dark};
    background-color: ${({ theme }) => theme.light};
    &:focus {
      border-color: ${({ theme }) => theme.primary};
      box-shadow: 0 0 5px ${({ theme }) => theme.primary};
    }
    &.validate:valid:not(:focus):not(:placeholder-shown) {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjNGJiNTQzIiBkPSJNMTczLjg5OCA0MzkuNDA0bC0xNjYuNC0xNjYuNGMtOS45OTctOS45OTctOS45OTctMjYuMjA2IDAtMzYuMjA0bDM2LjIwMy0zNi4yMDRjOS45OTctOS45OTggMjYuMjA3LTkuOTk4IDM2LjIwNCAwTDE5MiAzMTIuNjkgNDMyLjA5NSA3Mi41OTZjOS45OTctOS45OTcgMjYuMjA3LTkuOTk3IDM2LjIwNCAwbDM2LjIwMyAzNi4yMDRjOS45OTcgOS45OTcgOS45OTcgMjYuMjA2IDAgMzYuMjA0bC0yOTQuNCAyOTQuNDAxYy05Ljk5OCA5Ljk5Ny0yNi4yMDcgOS45OTctMzYuMjA0LS4wMDF6Ii8+PC9zdmc+") !important;
      background-position: right center;
      background-repeat: no-repeat;
      background-size: contain;
      background-origin: content-box;
    }
    &.validate:invalid:not(:focus):not(:placeholder-shown) {
      border-color: #b00020;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNTIgNTEyIj48cGF0aCBmaWxsPSIjYjAwMDIwIiBkPSJNMjQyLjcyIDI1NmwxMDAuMDctMTAwLjA3YzEyLjI4LTEyLjI4IDEyLjI4LTMyLjE5IDAtNDQuNDhsLTIyLjI0LTIyLjI0Yy0xMi4yOC0xMi4yOC0zMi4xOS0xMi4yOC00NC40OCAwTDE3NiAxODkuMjggNzUuOTMgODkuMjFjLTEyLjI4LTEyLjI4LTMyLjE5LTEyLjI4LTQ0LjQ4IDBMOS4yMSAxMTEuNDVjLTEyLjI4IDEyLjI4LTEyLjI4IDMyLjE5IDAgNDQuNDhMMTA5LjI4IDI1NiA5LjIxIDM1Ni4wN2MtMTIuMjggMTIuMjgtMTIuMjggMzIuMTkgMCA0NC40OGwyMi4yNCAyMi4yNGMxMi4yOCAxMi4yOCAzMi4yIDEyLjI4IDQ0LjQ4IDBMMTc2IDMyMi43MmwxMDAuMDcgMTAwLjA3YzEyLjI4IDEyLjI4IDMyLjIgMTIuMjggNDQuNDggMGwyMi4yNC0yMi4yNGMxMi4yOC0xMi4yOCAxMi4yOC0zMi4xOSAwLTQ0LjQ4TDI0Mi43MiAyNTZ6Ii8+PC9zdmc+");
      background-position: right center;
      background-repeat: no-repeat;
      background-size: contain;
      background-origin: content-box;
    }
  }
  input[type="submit"] {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.contrast};
    border: 0;
    padding: 0.75rem 1rem;
    text-align: center;
    font-size: 1.2rem;
    margin-left: auto;
  }
  @media (min-width: ${({ theme }) => theme.mobileBreakpoint}px) {
    input[type="submit"] {
      width: auto;
    }
  }
`

export default ContactForm
