import React from "react"
import styled from "styled-components"

const FreeFormTextLayout = ({ className, html, body, children }) => {
  return (
    <section className={className}>
      <div
        className="freeform-text__inner"
        dangerouslySetInnerHTML={{ __html: html || body.childMarkdownRemark.html || children }}
      ></div>
    </section>
  )
}

const FreeFormTextSection = styled(FreeFormTextLayout)`
  width: 100%;
  display: flex;
  justify-content: center;
  .freeform-text__inner {
    width: 100%;
    max-width: ${({ theme }) => theme.maxWidth}px;
    padding: 3rem 2rem;
    text-align: ${({ textAlignment }) => textAlignment};
  }

  img {
    max-width: 100%;
  }
`

export default FreeFormTextSection
